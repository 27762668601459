@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
header {
  margin: 0;
  padding: 0;
}
.App {
  /* text-align: center; */
  margin: 0px;
  padding: 0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.imgs-wrapper{
  position: relative;
}
.App-header {
  background: linear-gradient(320deg, #ACF9CC 0%, #34B4AB 100%);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
.top-text{
  position: absolute;
  margin:auto;
  top: 190px;
  font-family: 'Inter', sans-serif;
  font-weight : 400;
  color: white;
  font-size: 54px;
}
.avatar-img{
  width: 40%;
  position: absolute;
  bottom: 0;
  margin: 0;
}

.App-link {
  color: #61dafb;
}
.App-body{
  min-height: calc(100vh - 70px);
  width: 100%;
}
.floating-text{
  position: absolute;
  top: 0px;
  left: 10px;
  color: white;
  margin-left: 20px;
  margin-top: 20px;
}
.floating-text .main-title{
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 20px;
  
}
.floating-text p{
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 20;
  margin: 0;
}
.projects-text{
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 20px;
}
.github-icon{
  position: absolute;
  z-index: 99;
  bottom: 10px;
  width: 7%;
  cursor: pointer;
  /* left: 10px; */
}
.square{
  width: 66px;
  height: 78px;
  background-color: gray;
  border-radius: 10px;
  cursor: pointer;
}
.project-title{
  font-family: 'Inter', sans-serif;
font-size: 18px;
font-weight: 400;
}
.project-subtitle{
  font-family: 'Inter', sans-serif;
font-size: 16px;
color: #828282;
}
.projects-list{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  padding-top: 20px;
  
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .avatar-img{
    min-width: 60%;
    /* height: inherit; */
    position: absolute;
    bottom: 0px !important;
    margin-bottom: 0px;
  }
  .github-icon{
    width: 10%;
  }
  .projects-list{
    flex-direction: column;
  }
  .App-body{
    padding-bottom: 40px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
